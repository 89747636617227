export type ToastType = 'success' | 'error' | 'info' | 'warn';

export interface Toast {
    id: number;
    content: string;
    type: ToastType;
    active: boolean;
}

interface Store {
    toasts: Ref<Toast[]>;

    success(content: string): void;

    error(content: string): void;

    info(content: string): void;

    warn(content: string): void;

    remove(toast: Toast): void;
}

export const useToastStore = defineStore('Toast', (): Store => {
    // state
    const toasts = ref<Toast[]>([]);

    // actions
    const add = (content: string, type: ToastType): void => {
        toasts.value.push({
            id:     useId(),
            type,
            content,
            active: true,
        });
    };

    const success = (content: string): void => {
        add(content, 'success');
    };

    const error = (content: string): void => {
        add(content, 'error');
    };

    const info = (content: string): void => {
        add(content, 'info');
    };

    const warn = (content: string): void => {
        add(content, 'warn');
    };

    const remove = (toast: Toast): void => {
        toast.active = false;
    };

    return {
        // state
        toasts,

        // actions
        success,
        error,
        info,
        warn,
        remove,
    };
}, {
    persist: false,
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot));
}
