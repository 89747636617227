import { setupCache } from 'axios-cache-interceptor';
import axios          from 'axios';

const headers = {
    'Content-Type': 'application/json',
};

const instance = axios.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    baseURL:         import.meta.env.VITE_APP_API_URL,
    withCredentials: true,
    headers,
});

const api = setupCache(instance);

api.interceptors.request.use(
    (config) => {
        if (config?.headers) {
            const auth = useAuthStore();
            if (auth.session) {
                config.headers.Authorization = `Bearer ${auth.session}`;
            }
        }

        return config;
    },
    async (error) => Promise.reject(error),
);

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalConfig    = error.config;
        originalConfig._attempt = originalConfig._attempt ? originalConfig._attempt + 1 : 1;
        const toast             = useToastStore();

        if (error.response) {
            const auth = useAuthStore();

            if (!originalConfig._retry) {
                switch (error.response.status) {
                    case 401:
                        if (originalConfig.url !== '/v1/auth/me') {
                            await auth.me();

                            if (auth.session) {
                                instance.defaults.headers.common.Authorization = `Bearer ${auth.session}`;
                            }

                            originalConfig._retry = true;

                            return instance(originalConfig);
                        } else {
                            await auth.expired();
                        }

                        break;
                    case 408:
                    case 425:
                    case 429:
                    case 500:
                    case 502:
                    case 503:
                    case 504:
                        originalConfig._retry = originalConfig._attempt > 1;

                        return instance(originalConfig);
                    default:
                        if (error.response.data?.response) {
                            Array.isArray(error.response.data.response)
                            ? error.response.data.response.forEach((message: string) => toast.error(message))
                            : toast.error(error.response.data.response);
                        } else {
                            toast.error('something went wrong, try again later');
                        }

                        break;
                }
            } else {
                switch (error.response.status) {
                    case 401:
                        await auth.expired();

                        return Promise.reject(401);
                    default:
                        if (error.response.data?.response) {
                            Array.isArray(error.response.data.response)
                            ? error.response.data.response.forEach((message: string) => toast.error(message))
                            : toast.error(error.response.data.response);
                        } else {
                            toast.error('something went wrong, try again later');
                        }
                }
            }
        } else {
            if (originalConfig._attempt < 3) {
                return instance(originalConfig);
            } else {
                toast.error('something went wrong, try again later');
            }
        }

        return Promise.reject(error);
    },
);

export interface ApiResponse<T> {
    response: T;
}

export interface Collection<T> {
    data: T[];
    prev: boolean;
    next: boolean;
}

export { api };
