import * as Sentry from '@sentry/vue';

export const useErrorHandler = (action: string, error: unknown): void => {
    console.error(action, { error });

    if (import.meta.env.MODE !== 'development') {
        Sentry.captureException(error);
    }
};

export const useThrowableErrorHandler = (action: string, error: unknown): void => {
    useErrorHandler(action, error);

    throw error;
};
