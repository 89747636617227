import { Composer, createI18n } from 'vue-i18n';
import en                       from '~/locales/en.ts';
// import messages                 from '@intlify/unplugin-vue-i18n/messages';

// until they fix importing ts files we have to import them manually
// @see https://github.com/intlify/bundle-tools/issues/266
const messages = {
    en,
};

const instance = createI18n({
    legacy: false,
    locale: 'en',
    messages,
});

export default instance;

export const { t } = instance.global as Composer;
