import { t } from '~/i18n';
import {
    useLogin,
    useLogout,
    useMe,
}            from './auth.api';

export interface ILogin {
    account: IUser;
    session: string;
}

interface Store {
    account: Ref<IUser | null>;
    session: Ref<string | null>;
    isLoggedIn: ComputedRef<boolean>;
    isActivated: ComputedRef<boolean>;

    login(email: string, password: string, redir?: string): Promise<void>;

    logout(): Promise<void>;

    expired(): Promise<void>;

    me(): Promise<void>;
}

export const useAuthStore = defineStore('auth', (): Store => {
    const toastStore = useToastStore();
    const router     = useRouter();

    // state
    const account = ref<IUser | null>(null);
    const session = ref<string | null>(null);

    // getters
    const isLoggedIn  = computed<boolean>(() => !!session.value);
    const isActivated = computed<boolean>(() => account.value?.active === true);

    // actions
    const destroy = (): void => {
        account.value = null;
        session.value = null;
    };

    const set = (data: ILogin): void => {
        account.value = data.account;
        session.value = data.session;
    };

    const login = async (email: string, password: string, redir?: string): Promise<void> => {
        try {
            const data = await useLogin(email, password);

            set(data);

            toastStore.success(t('features.auth.login.toasts.success'));

            await router.push(redir || '/');
        } catch (e) { useErrorHandler('auth/login', e); }
    };

    const logout = async (): Promise<void> => {
        destroy();

        try {
            await useLogout();

            toastStore.success(t('features.auth.logout.toasts.success'));

            await router.push('/');
        } catch (e) { useErrorHandler('auth/logout', e); }
    };

    const expired = async (): Promise<void> => {
        destroy();

        try {
            await useLogout();

            toastStore.success(t('features.auth.session.expired'));

            const { fullPath } = router.currentRoute.value;

            await router.push(`/auth/login?r=${fullPath}`);
        } catch (e) { console.log('auth/expired', { e }); }
    };

    const me = async (): Promise<void> => {
        try {
            const data = await useMe();

            set(data);
        } catch (e) { useErrorHandler('auth/me', e); }
    };

    return {
        // state
        account,
        session,

        // getters
        isLoggedIn,
        isActivated,

        // actions
        login,
        logout,
        expired,
        me,
    };
}, {
    persist: true,
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
