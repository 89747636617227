export const useLogin = async (email: string, password: string): Promise<ILogin> => {
    const { data } = await api.post<ApiResponse<ILogin>>('/v1/auth/login', {
        email,
        password,
    }, {
        cache: false,
    });

    return data.response;
};

export const useLogout = async (): Promise<void> => {
    await api.post<ApiResponse<boolean>>('/v1/auth/logout', {}, {
        cache: false,
    });
};

export const useMe = async (): Promise<ILogin> => {
    const { data } = await api.post<ApiResponse<ILogin>>('/v1/auth/me', {}, {
        cache: false,
    });

    return data.response;
};

